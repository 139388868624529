#__next {
  /* tier name */

  [data-pricing-product-name-badge='0'] {
    @apply bg-moss-100 text-moss-700;
  }
  [data-pricing-product-name-badge='1'] {
    @apply bg-yellow-300/20 text-yellow-700;
  }
  [data-pricing-product-name-badge='2'] {
    @apply bg-amber-600/20 text-amber-800;
  }

  /* purchase button */

  [data-pricing-product-checkout-button='0'] {
    @apply bg-moss-200/50 text-moss-800 hover:bg-moss-200/80;
  }
  [data-pricing-product-checkout-button='1'] {
    @apply bg-yellow-400 text-black py-5 hover:bg-yellow-300 relative after:content-[''] shadow-yellow-200 shadow-md after:w-full after:h-full after:blur-lg after:opacity-80 after:bg-yellow-300 after:absolute;
  }
  [data-pricing-product-checkout-button='2'] {
    @apply bg-moss-200/50 text-moss-800 hover:bg-moss-200/80;
  }
  [data-pricing-product-checkout-button]:disabled {
    @apply cursor-wait;
  }

  /* PPP box */
  [data-pricing-product-ppp='0'] {
    p {
      @apply sr-only;
    }
    label {
      @apply text-sm;
    }
  }
  [data-pricing-product-ppp='1'] {
    @apply max-w-sm p-5 border border-gray-100;
  }
  [data-pricing-product-ppp='2'] {
    label {
      @apply text-sm;
    }
    p {
      @apply sr-only;
    }
  }
}

/* Countdown box */
[data-pricing-product-sale-countdown='0'] {
  @apply hidden;
}

[data-pricing-product-sale-countdown='2'] {
  @apply hidden;
}
