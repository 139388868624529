@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@reach/dialog/styles.css';
@import './prism-theme.css';
@import './nprogress.css';
@import './icons.css';
@import './quiz.css';
@import '@skillrecordings/player/dist/skillrecordings-player.css';
@import './pricing.css';

:root {
  --reach-skip-nav: 1;
  scroll-padding-top: 80px;
}

#__next {
  @apply flex flex-col min-h-screen text-gray-900 bg-gray-50 antialiased;
}

.cueplayer-react-side-panel-control {
  visibility: hidden;
  width: 0 !important;
}

.cueplayer-react-cue-bar {
  display: none;
}

#subscribe [data-sr-convertkit-subscribe-form] {
  @apply flex flex-col w-full max-w-[340px] mx-auto;
  [data-sr-input] {
    @apply block mb-4 w-full px-4 py-3 text-white placeholder-white placeholder-opacity-60 bg-green-800 bg-opacity-50 border border-white border-opacity-20 rounded-lg shadow sm:text-base sm:leading-6;
  }
  [data-sr-input-label] {
    @apply font-medium pb-1 inline-block;
  }
  [data-sr-input-asterisk] {
    @apply opacity-50;
  }
  [data-sr-button] {
    @apply font-nav pt-4 pb-5 mt-4 flex items-center justify-center rounded-lg text-black bg-yellow-500 hover:bg-opacity-100 transition font-bold text-lg focus-visible:ring-yellow-200 hover:scale-105 hover:-rotate-1 hover:bg-yellow-400;
  }
}

[data-workshop-interest] {
  [data-sr-convertkit-subscribe-form] {
    @apply w-full flex md:flex-row flex-col md:items-end justify-center max-w-none md:space-x-5 space-x-0 md:space-y-0 space-y-5 text-left;
    [data-sr-input] {
      @apply inline-block w-full px-4 py-3 text-black mb-0 placeholder-gray-500 placeholder-opacity-50 bg-gray-100 border border-white border-opacity-20 focus:border-transparent rounded-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-base sm:leading-6;
    }
    [data-sr-input-label] {
      @apply font-medium;
    }
    [data-sr-input-asterisk] {
    }
    [data-sr-button] {
      @apply inline-flex items-center px-8 py-3 mt-0 text-base flex-shrink-0 font-semibold leading-6 tracking-tight text-white transition duration-300 ease-in-out transform border-none rounded-full shadow-xl bg-gradient-to-r from-blue-600 to-indigo-600 focus:outline-none focus:ring-2 focus:ring-black hover:scale-105;
    }
  }
}

[data-article] {
  [data-sr-convertkit-subscribe-form] {
    @apply w-full flex flex-col justify-center max-w-none text-left;
    [data-sr-input] {
      @apply block w-full px-4 py-3 text-white placeholder-white placeholder-opacity-60 bg-green-800 bg-opacity-50 border border-white border-opacity-20 rounded-lg shadow sm:text-base sm:leading-6;
    }
    [data-sr-input-label] {
      @apply font-medium pb-1 inline-block;
    }
    [data-sr-input-asterisk] {
      @apply opacity-50;
    }
    [data-sr-button] {
      @apply mt-4 flex items-center justify-center rounded-lg text-black bg-yellow-500 hover:bg-opacity-100 transition pt-4 pb-5 text-lg focus-visible:ring-yellow-200 hover:scale-105 hover:-rotate-1 hover:bg-yellow-400;
    }
  }
}

[data-reach-skip-nav-link] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

[data-reach-skip-nav-link]:focus {
  @apply px-4 py-2 text-lg font-semibold bg-white rounded-md overflow-hidden fixed top-5 left-5 w-auto h-auto;
  z-index: 1;
  clip: auto;
}

[data-reach-skip-link]:focus {
  @apply z-50 left-2 top-2 flex items-center justify-center text-base outline-orange-500;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
/* .js-focus-visible :focus:not(.focus-visible) { outline: none; } */

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  @apply outline-none;
}

/*
  Optionally: Define a strong focus indicator for keyboard focus.
  If you choose to skip this step then the browser's default focus
  indicator will be displayed instead.
*/
/* .js-focus-visible .focus-visible {
  @apply outline-none ring-2 ring-green-500;
} */

[data-js-focus-visible] [data-focus-visible-added] {
  @apply outline-none ring-2 ring-green-500;
}

#rewardId {
  @apply w-full z-50;
}

/* player theme */

.cueplayer-react .cueplayer-react-progress-bar [data-reach-slider-range] {
  @apply bg-green-600;
}

.cueplayer-react
  .cueplayer-react-progress-bar
  .cueplayer-react-progress-slider
  [data-reach-slider-handle]:focus {
  @apply outline-green-500;
}

.no-marker {
  ::-webkit-details-marker {
    display: none;
  }
}

.cueplayer-react-listbox {
  [data-radix-collection-item][data-highlighted] {
    @apply bg-green-600/60;
  }

  [data-radix-collection-item][data-state='checked'] {
    @apply bg-green-600;
  }
}

#tip {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full flex-col gap-2 text-left md:flex-row;
    [data-sr-input-wrapper] {
      @apply w-full;
    }
    [data-sr-input-wrapper]:first-of-type {
      @apply md:w-2/3;
    }
    [data-sr-input] {
      @apply w-full rounded-md bg-gray-200/60 px-3 py-2 shadow-inner placeholder:text-gray-500;
    }

    [data-sr-input-label] {
      @apply sr-only;
    }
    [data-sr-button] {
      @apply font-nav flex flex-shrink-0 pt-1 pb-1.5 px-4 focus-visible:outline-black items-center justify-center rounded-lg text-black bg-yellow-400 hover:bg-opacity-100 transition font-bold text-lg hover:bg-yellow-300 focus-visible:ring-0;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}
