.gg-arrow-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-down::after,
.gg-arrow-down::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  bottom: 4px;
}
.gg-arrow-down::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
  left: 7px;
}
.gg-arrow-down::before {
  width: 2px;
  height: 16px;
  left: 10px;
  background: currentColor;
}

.gg-arrow-top-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  transform: scale(var(--ggs, 1));
  border-right: 2px solid;
}
.gg-arrow-top-right::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 14px;
  height: 2px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 3px;
  right: -3px;
}

.gg-arrow-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-arrow-right::after,
.gg-arrow-right::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
}
.gg-arrow-right::after {
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}
.gg-arrow-right::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

.gg-calendar,
.gg-calendar::before {
  display: block;
  box-sizing: border-box;
}
.gg-calendar {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-top: 4px solid;
  border-radius: 3px;
}
.gg-calendar::before {
  content: '';
  position: absolute;
  width: 10px;
  border-radius: 3px;
  left: 2px;
  background: currentColor;
  height: 2px;
  top: 2px;
}
